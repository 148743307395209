.caladea-regular {
  font-family: "Caladea", serif;
  font-weight: 400;
  font-style: normal;
}

.caladea-bold {
  font-family: "Caladea", serif;
  font-weight: 700;
  font-style: normal;
}

.caladea-regular-italic {
  font-family: "Caladea", serif;
  font-weight: 400;
  font-style: italic;
}

.caladea-bold-italic {
  font-family: "Caladea", serif;
  font-weight: 700;
  font-style: italic;
}

.cinzel-decorative-regular {
  font-family: "Cinzel Decorative", serif;
  font-weight: 400;
  font-style: normal;
}

.cinzel-decorative-bold {
  font-family: "Cinzel Decorative", serif;
  font-weight: 700;
  font-style: normal;
}

.cinzel-decorative-black {
  font-family: "Cinzel Decorative", serif;
  font-weight: 900;
  font-style: normal;
}
